import React, { useEffect, useState } from 'react';
import { readFirestore } from './FirestoreController';
import RoutingController from './RoutingController';
import EventType from '../MODELS/EVENT/Event';
import { setList } from '../MODELS/EVENT/EventModel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventContext from '../MODELS/EVENT/EventContext';
import { addEventToFirestore, eventRef, login } from '../TOOLS/ConfigFirebase';
import { addEmitHelpers } from 'typescript';


const ContextController: React.FunctionComponent = () => {

    const [ loaded, setLoaded ] = useState(false);
   
// ------------------------------------------------------------------------------------------------------------------- EVENTS
    const [ events, setEvents ] = useState<EventType[]>([]);
    const [ event3, setEvent3 ] = useState<EventType[]>([]);
    const updateEvents = (csvevent: any) => {
      setEvents(csvevent)
      updateEvent3(csvevent)
    }

    const updateEvent3 = (events: EventType[]) => { setEvent3(setList(events)) }

    useEffect(() =>{
      login()
      .then(response => {
        readFirestore(eventRef)
          .then(response => { 
            let array: any = [];
            array = response
            array.sort((a: any, b: any) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
            updateEvents(array) 
          })
          .catch(error => { })
        })
      .catch(error => { })
    }, [loaded])

    return(
      <EventContext.Provider value = {{events, event3, updateEvents, updateEvent3}}>
        <RoutingController />
        <ToastContainer />
      </EventContext.Provider >
    )

}

export default ContextController