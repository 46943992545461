import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDItKzfi2er6qMdEeRdl4V8se2BGPcS1dE",
  authDomain: "hvbt-da371.firebaseapp.com",
  projectId: "hvbt-da371",
  storageBucket: "hvbt-da371.firebasestorage.app",
  messagingSenderId: "196059857619",
  appId: "1:196059857619:web:9943f5a419a0f3966b4a26"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Initialize Auth
export const auth = getAuth();

// Initialize Firestore
export const database = getFirestore();
export const eventRef = collection(database, 'event')
export const usersJson = require('../event.json')

export const login = () => {
  return new Promise<any>(async (resolve, reject) => {
  signInWithEmailAndPassword(auth, process.env.REACT_APP_FIRESTORE_EMAIL!, process.env.REACT_APP_FIRESTORE_PASSWORD!)
  .then(cred => { resolve(cred) })
  .catch(error => { reject(error) })
  })
 }

 export const addEventToFirestore = () => {
  usersJson.map((element:any) => {
   addDoc(eventRef, {
     id: element.id,
     date: element.date,
     name: element.name,
     picture: element.picture,
     pdf: element.pdf,

   })
  } )
 }