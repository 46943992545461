import { doc, getCountFromServer, query, where, getDocs, addDoc, updateDoc } from "firebase/firestore";
import { database } from "./ConfigFirebase";

export const getData = (reference) => {
  return new Promise((resolve, reject) => {
    let array = []
    getDocs(reference)
      .then((snapshot) => {
        snapshot.docs.forEach((element) => { array.push({...element.data(), id: element.data().id, firestoreId: element.id}) })
        resolve(array) 
      })
      .catch(err => { reject(err.message)})
  })
};
