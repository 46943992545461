import EventType from "./Event"
export const setList = (events: EventType[]) => {
    
    let actualId = nextEventId(events)!.id;
    let listOfThree: EventType[] = [];
    listOfThree.push(events[(actualId-2)])
    listOfThree.push(events[(actualId-1)])
    listOfThree.push(events[(actualId)])
    return listOfThree;

}

export const nextEventId = (events: EventType[]) => {
    return events.find((event) => (Date.parse(event.date) > Date.now()))
}

export const jumpEvent3 = (events: EventType[], event3: EventType[], cursor: number) => {
    let listOfThree: EventType[] = [];
    let actualId = setNextEventId(events, event3, cursor);

    listOfThree.push(events[(actualId-2)])
    listOfThree.push(events[(actualId-1)])
    listOfThree.push(events[(actualId)])
    return listOfThree;

}

const setNextEventId = (events: EventType[], event3: EventType[], cursor: number) => {
    let nextEventId = 0;
    let lastId = events.length;
    if(cursor === 1){
        if(+event3[1].id < (+lastId -2)){
            nextEventId = +event3[1].id + 1;
        }else{
            nextEventId = +event3[1].id
        }
    }else{
        if(+event3[1].id > 2){
            nextEventId = +event3[1].id - 1;
        }else{
            nextEventId = +event3[1].id
        }
    }
    return nextEventId;
}



