import { eventRef } from '../TOOLS/ConfigFirebase';
import { getData } from '../TOOLS/FirestoreQueries';

export const readFirestore = ( reference:any ) => {
  return new Promise((resolve, reject) => {
    getData(reference)!
    .then((response) => { resolve(response!) })
    .catch(err => { reject(err.message)})
  })
}
